<template>
	<div class="banner" :class="type">
		<div class="bottom">
			<div class="tips">{{headObj.title}}</div>
			<div class="info">
				<div class="name">{{headObj.desc}}</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'nav-heade',
		props: {
			headObj: {
				type: Object,
				default: () => {}
			},
			type: String,
		}
	}
</script>

<style lang="scss" scoped>
	.banner {
		height: 456px;
		background-size: cover;
		background-repeat: no-repeat;
		padding: 16px 24px;
		box-sizing: border-box;
		&.material{
			background-image: url('../static/images/material-bg.png');
		}
		&.user{
			background-image: url('../static/images/user-identity-bg.png');
		}
		&.bank{
			background-image: url('../static/images/user-identity-bg.png');
		}
		.bottom {
			color: #fff;
			text-align: left;
			margin-top: 30px;

			.tips {
				font-size: 20px;
				margin-bottom: 10px;
			}

			.info {
				color: rgba(255, 255, 255, 0.7);
			}
		}
	}
</style>
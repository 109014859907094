<template>
  <div class="min_h_100_vh dis_flex flex_dir_col  bg_col_F5F7F9">

    <van-nav-bar title="提前结清" left-arrow @click-left="backIndex()" right-text="退出" @click-right="logout()" >

    </van-nav-bar>
    <div
      class="
        bg_col_fff
        bor_rad_4
        box_sha_0_0_8_8_black_80_per

        m_0_16 m_t_16
      "
    >
      <!-- 借款详情 -->
      <van-cell-group>
        <van-cell title="本金(元)" :value="order?order.principal:'0.00'" />
        <van-cell title="租息(元)" :value="order?order.interest:'0.00'" />
        <van-cell title="罚息(元)" :value="order?order.fine:'0.00'" />
        <van-cell title="违约金(元)" :value="order?order.advancePenalty:'0.00'" />
        <van-cell title="还款时间" :value="order?order.planRepayDate:'-'" />
        <van-cell   value-class="repayAmt" >

          <template  #title>
            合计结清金额(元)
            <van-popover
              v-model="showPopover"
              trigger="click"
              theme="light"
              placement="top"
            >
              <van-grid
                :border="false"
                column-num="1"
                style="width: 260px;height: 26px;line-height: 26px;line-height: 26px;text-align: center;"
              >
                <van-grid-item
                  v-html="actions"
                  @click="showPopover = false"
                />
              </van-grid>
              <template   #reference>
                <van-icon name="info-o"  />
              </template>
            </van-popover>
          </template>

          <template>
            {{order?order.repayAmt:'0.00'}}
          </template>
        </van-cell>
        </van-cell-group>

      <div style="margin: 4.267vw;">
      <van-button  class="h_40 m_t_32" type="primary" block @click="prepayCalcPayH5">支付</van-button>
      </div>
    </div>
    </div>

</template>

<script>
import { Toast } from 'vant';

import {authRevokeDelete, getPrepayCalc, prepayCalcPayH5} from '@/api/index';

export default {
  components: { },
  data() {
    return {
      userInfo: {},
      order:{},
      repayDisabled: false,
      showPopover: false,
      actions : '<div class="col_333 font_12 m_l_4">温馨提示:如有疑问,详情咨询<span class="col_FF5252">400-008-3336</span></div>'
      //
    };
  },
  computed: {},
  mounted() {
    if (localStorage.userInfo) {
      const u = JSON.parse(localStorage.userInfo);
      this.userInfo = u;
    }

    this.calculate();


    //
  },
  methods: {
    /**
     * 获取还款计划
     * @returns {Promise<void>}
     */
    async calculate() {

      try {

        Toast.loading({
          duration: 10 * 1000,
          message: '请稍后..',
          forbidClick: true,
        });
        this.order = await getPrepayCalc();
        Toast.clear();
      }catch (err){
        Toast(err);
      }
    },

    backIndex(){
       this.$router.replace('/index');
    },


    /**
     * 还款
     * @returns {Promise<void>}
     */
    async prepayCalcPayH5() {

      try {
              Toast.loading({
                duration: 10 * 1000,
                message: '请稍后..',
                forbidClick: true,
              });
            this.repayDisabled = true;
            let result = await prepayCalcPayH5();

            if(result.status == 200){
              window.top.location.href = result.data.h5Url;
              this.repayDisabled = false;
            }else {
              Toast.fail(result.msg);
              this.repayDisabled = false;
            }
        Toast.clear();

    }catch (err){
      Toast(err);
    }
    },

    // 登出
    logout() {
      this.$dialog
        .confirm({
          title: '提示',
          message: '您是否确认登出？',
        })
        .then(async () => {
          try {
            Toast.loading({
              duration: 10 * 1000,
              message: '请稍后..',
              forbidClick: true,
            });

            await authRevokeDelete(this.userInfo.token);

            Toast.clear();

            localStorage.removeItem('userInfo');
            this.$router.replace('/login');

            //
          } catch (err) {
            Toast(err);
          }

          //
        })
        .catch(() => {});

      //
    },


    //
  },
};
</script>

<style >
.repayAmt{
  font-size: 20px;font-weight: bold; color: #d54343;
}
</style>

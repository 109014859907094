<template>
   <div class="material-content">
    <van-icon name="arrow-left"
             @click="go()"
    ></van-icon>
    <navHeader	:headObj="{title:'还款卡绑定',desc:'Bind bank card'}" type="bank"></navHeader>

    <!-- <view class="step-box" v-if="this.OrderClientVo.capitalNo === 'TSZL'">
      <view :class="['step', checkBind ? 'active' : '']">
        <view class="round">1</view>
        <view class="txt">绑卡</view>
      </view>
      <view class="hr"></view>
      <view :class="['step', !checkBind ? 'active' : '']">
        <view class="round">2</view>
        <view class="txt">绑卡确认</view>
      </view>
    </view> -->
 <div>
<div>
  <van-cell-group>
  <van-cell title="银行卡号" value="点击选卡" is-link @click="showPopup" />


  <van-cell title="账号名" :value="info.ownerBankNumber"/>
  <van-cell title="开户银行" :value="changeBanckName(info.ownerBank)"/>
  <van-cell title="银行预留手机号" :value="info.ownerPhone"/>

    <van-field
  v-model="info.capitalMsgCode"
  center
  type="number"
  label="短信验证码"
  maxlength="6"
  placeholder="请输入短信验证码"
>
  <template #button>
    <van-button size="small" @click="judgeSendCode(sendCodeCountDown)" type="primary">  {{
                sendCodeCountDown > 0
                ? sendCodeCountDown + "秒后重发"
                : "发送验证码"
                }}</van-button>
  </template>
</van-field>

</van-cell-group>

</div>
  <div>
    <div>
    </div>
    <div class="bottom-btn">
      <label class="clause df"> 
        
      <van-checkbox v-model="checked" style="float: left;">
      
        <div class="clause-info df">
          我已阅读并同意
        </div>
      </van-checkbox>   
       
        <div class="clause-info df">
      <span class="info" @click="openContractPro(info.ownerBank)">《快捷支付服务协议》</span>
         </div>

      </label>
      <van-button type="primary" size="large" @click="saveBank">提交</van-button>
       </div>
     </div>
  </div>
  <div>
<van-popup v-model="show" position="bottom" @round="true" @close="hideCardChoseViewAction">
  <div class="popup-down-wrapper">

   <div class="popup-down popup-down-select" v-if="step === 'card-select'">
          <div class="popup-down-header">
            <div class="header-icon"></div>
            <div class="header-title">
              <span>选择还款卡</span>
            </div>
          </div>
          <div class="popup-down-content">
            <div v-for="(item,index) in this.bankList" :key="index+1">
              <van-cell :value="item.ownerBankNumber" @click="bindPickerChange(item, index)">
                <template>

                  <span  class="m_l_16">{{item.ownerName}}</span>

                  <span class="m_l_16">{{item.ownerBankNumber}}</span>
                </template>
              </van-cell>
            </div>

          </div>
          <div
            v-if="addBank"
            class="popup-down-footer"
            @click="changeStep('card-add')"
          >
            <span>+添加银行卡</span>
          </div>
        </div>
     <div  class="popup-down popup-down-add" v-if="step === 'card-add'">
      <div class="popup-down-header">
            <div class="header-icon"></div>
            <div class="header-title">
              <van-icon name="arrow-left"
                @click="changeStep('card-select')"
              ></van-icon>
              <span class="title">添加银行卡</span>

            </div>
          </div>

     <div class="popup-down-add-content">
      <van-cell-group>
  <van-cell title="开户银行" :value="changeBanckName(this.addBankData.ownerBank)" is-link @click="showPicker=true" />
<van-popup v-model="showPicker" position="bottom" get-container="body">
<van-picker show-toolbar  @cancel="showPicker = false" @confirm="onConfirm" :value="bindIndex" :columns="bankLimitList"/>

</van-popup>

  <van-cell title="持卡人" :value="addBankData.name"/>
  <van-cell title="身份证号" :value="addBankData.idNumber"/>
  <van-cell title="手机号" :value="addBankData.bankMobile"/>
  <van-field
  v-model="addBankData.bankCardNo"
  center
  label="银行卡号"
  placeholder="请输入银行卡号"
/>

    <van-field
  v-model="addBankData.platformMsgCode"
  center
  type="number"
  label="验证码"
  maxlength="6"
  placeholder="请输入短信验证码"
>
  <template #button>
    <van-button size="small" @click="bankSendCode()" type="primary">  {{
                bankSendCodeCountDown > 0
                ? bankSendCodeCountDown + "秒后重发"
                : "发送验证码"
                }}</van-button>
  </template>
</van-field>

</van-cell-group>
     </div>
     <div class="row">
      <div class="agree-view">
        <label class="clause df"> 
        
        <van-checkbox v-model="agreexybool" style="float: left;">
        
          <div class="clause-info df">
            我已阅读并同意
          </div>
        </van-checkbox>   
         
      <div class="clause-info df" style="float: left;">
        <span class="info" @click="openContract()" >《代扣协议》</span>
      </div>
  
        </label>
      </div>

     </div>
     <div class="popup-down-footer">
      <van-button type="primary" size="large" @click="addBankAction()">添加</van-button>
     </div>

     </div>
  </div>

</van-popup>
</div>




   </div>


</template>

<script>

import navHeader from "@/components/nav-header"

import { Toast } from 'vant';

import {
  REGEX_MOBILE,
  REGEX_BANK_CODE,
} from "../../utils/regex"
import {getOrderDetail,showBankLimit,userBankList,orderBankRepay,bankCardBin,scqBaofooBindBankSms,scqUserBankSave,reBankBind} from '@/api/index';


export default {
  components: {navHeader},
  data() {
    return {
      OrderClientVo:{
        baseVo:null,
        carVo:null,
        authenticateVo:null,
        step:null,
        ddCarCheck:false,
        baseInfoCheck:false,
        bizNo:"",
        contractPath:""
      },
      show:false,
      showPicker:false,
      zifangpopup:false,
      checked:0,
      agree:0,
      remarks: "",
      bindIndex:null,
      index: 0,
      zifangCode:null,
      Bankarray: [],
      bankList: [],
      info: {
        ownerBank:null,
        ownerPhone:null,
        capitalMsgCode:null,
      },
      sendCodeCountDown: 0,
      sendCodeInterval: null,
      bankInfo: {},
      step: 'card-select', // card-select card-add
      bankSendCodeCountDown:0,
      bankSendCodeInterval: null,
      agreexybool: false,
      addBankData: {
        name: '',
        idNumber: '',
        bankCardNo: '',
        bankMobile: '',
        //新增绑卡短信发送
        platformMsgCode: '', //平台短信码
      },
      nowRepayBank: '',
      bankIndex: 0,
      offFlag: true,
      checkBind: '',
      pdfUrl: '',
      isShowPdf: false,
      pdfh5: null,
      bindApplyNo: null,
      bankLimitList:[],
      bankCodeList:[],
    }
  },

  created(){
       this.getOrderDetail();
       this.getBankLimit();
       this.orderBankRepay();
     

  },
  methods: {


    // gotoPage(path) {
    //   this.$router.push(path)
    //   this.$store.state.iframeSrc = this.$route.meta.link
    //   this.$store.state.iframeTitle = this.$route.meta.title
    // },
    scBaseStringJudge(string) {
      if (string === null) {
        return false;
      }
      if (string === undefined) {
        return false;
      }
      if (string === "") {
        return false;
      }
      if (string === "null") {
        return false;
      }
      if (string === "undefined") {
        return false;
      }
      return true;
    },



    openContract(){
      window.open('/api/repayBank/contract/dewu/pay/protocol')
    },
    openContractPro(index){
      console.log("index",index)``
      window.location.href=`/api/repayBank/contract/dewu/pay/protocol/${index}`
    },
 
    go(){
      this.$router.replace('/index');
    },
    changeBanckName(value){
        let bankName
        console.log('----',this.checked)
        this.bankCodeList.map((item,index)=>{
            if(item == value){
                bankName = this.bankLimitList[index]
            }
        })
       console.log("bankName",bankName)
        return bankName

    },

    orderBankRepay() {
      orderBankRepay()
        .then(res => {
          console.log("res",res)
          this.nowRepayBank = res;

          if (!res.data || !res.data.acctNo) {
            this.userBankList();
          } else {
            this.userBankList(this.nowRepayBank);
          }
        })
        .catch(err => {

          Toast.fail(err.message || err.msg);
        });
    },

    userBankList(data) {
        userBankList().then((res) => {
          console.log("res",res)
        this.Bankarray = res.map((item) => item.bankCardNo);
        if (data) this.bankIndex = this.Bankarray.findIndex((item) => item === data.acctNo);
        this.bankList = res
       console.log("this.bankList",this.bankList)
        if(res.length>0){
            this.info = res[this.bankIndex]
        }
        console.log('---this.info',this.info)
        // if(this.info.ownerBank){
        //     this.bankCodeList.map((item,index)=>{
        //         if(item == this.info.ownerBank){
        //             this.info.ownerBank = this.bankLimitList[index]
        //         }
        //     })
        // }
        //this.validateBankCardBin()
      })
    },
    onConfirm(value, index) {
      //切换账号
      this.showPicker = false;
      this.bindIndex = index
      this.addBankData.ownerBank = this.bankCodeList[index]
      console.log('this.addBankData.ownerBank',this.addBankData.ownerBank)

    },
    async getOrderDetail(){

      this.OrderClientVo = await getOrderDetail()
      console.log("this.OrderClientVo",this.OrderClientVo)
     this.addBank()
    },
    judgeSendCode(val){
      if(!this.info.ownerPhone){
            return
        }
        if(val>0){
            return false
        }
        console.log(val)
        this.validateBankCardBin();
        this.timeGo()
        return
      if (this.sendCodeCountDown > 0) return;
      // 通盛 第二步强制查看
      if (['TSZL'].includes(this.userStatus.capitalNo) && !this.checkBind) {
        this.tsCode()
      } else {
        this.sendCode();
      }
    },
    bindBanckChange(){

    },
    async validateBankCardBin() {
        if(!this.info.ownerPhone){
            return false
        }

      try {
            bankCardBin({
            ownerBank: this.info.ownerBank,
            bankCard:this.info.ownerBankNumber,
            orderId: this.info.orderId,
            }).then((res)=>{
              console.log(res)
              if(res&&res.bindApplyNo){
              this.bindApplyNo = res.bindApplyNo
            }else{
              Toast.fail(res)
            }
            }) .catch(error => {
              // console.log(444,error)
              Toast.fail(error)
            })
      } catch (error) {
        Toast.fail(error.message || error.error || error.msg)
      }
    },


    // radioClick(){
    //   console.log(this.radio)
    //   // this.radio = this.radio=="1"?"0":"1";
    //   // console.log(this.radio)
    // },

    addBank() {
      let { capitalNo, appStatus, } = this.OrderClientVo;
      if (['TSZL'].includes(capitalNo)) return false;
      if (['LHYH'].includes(capitalNo) && appStatus != '10') return false;
      return true;
    },

    showPopup(){
      this.show = true
    },

      // 点击添加银行卡弹框
      changeStep(step){
      if (step === 'card-add') {
        this.addBankData.name = this.OrderClientVo.authenticateVo.clientName;
        this.addBankData.idNumber = this.OrderClientVo.authenticateVo.clientIdCard;
        this.addBankData.bankCardNo = this.OrderClientVo.authenticateVo.clientBankCard;
        this.addBankData.bankMobile = this.OrderClientVo.authenticateVo.clientPhone;
        this.step = 'card-add';
     
        return

      } else {
        this.hideCardChoseViewAction();
      }
      this.step = step;
    },
    hideCardChoseViewAction() { //选择银行卡列表背景点击事件

    console.log("关闭弹窗时")
      this.step = 'card-select';
      this.addBankData.bankCardNo = '';
      this.addBankData.bankMobile = '';
      this.addBankData.platformMsgCode = '';
      this.bankSendCodeCountDown = 0;
    },
    saveBank(){
      let info = this.info;
      if (!this.checked) {
        console.log('[][][][][][][[][][]]',this.checked)
        return Toast.fail("请先同意协议")
      }
      if (!this.scBaseStringJudge(info.capitalMsgCode)) {
        Toast.fail("请输入验证码");
        return;
      }
      let data = {
        bindApplyNo: this.bindApplyNo,
        orderId: this.info.orderId,
        // bankCard:this.info.ownerBankNumber,
        smsCode:info.capitalMsgCode,
      }
      reBankBind(data).then(
        (res) => {
          if(res.status==200){
            Toast.success(res.msg)
            this.$router.replace('/index');
          }
        
        },
        (e) => {
          Toast.fail(e.msg || e.error || e)
        }
      )




    },

    timeGo() {
      this.sendCodeTime = new Date().getTime()
      this.sendCodeCountDown = 60
      this.sendCodeInterval = setInterval(() => {
        this.sendCodeCountDown--
        if (this.sendCodeCountDown <= 0) {
          this.sendCodeCountDown = 0
          clearInterval(this.sendCodeInterval)
          this.sendCodeInterval = null
        }
      }, 1000)
    },

     //添加银行卡时候的计时器
     bankTimeGo() {
      this.bankSendCodeCountDown = 60;
      this.bankSendCodeInterval = setInterval(() => {
        this.bankSendCodeCountDown--;
        if (this.bankSendCodeCountDown <= 0) {
          this.bankSendCodeCountDown = 0;
          clearInterval(this.bankSendCodeInterval);
          this.bankSendCodeInterval = null;
        }
      }, 1000);
    },

// 添加银行卡发送验证码
async bankSendCode() {
      let { bankCardNo, bankMobile } = this.addBankData;
      if (!REGEX_BANK_CODE.test(bankCardNo)) {
        Toast.fail.showToast('请输入正确的银行卡号');
        return false;
      }
      if (!REGEX_MOBILE.test(bankMobile)) {
        Toast.fail('请输入正确的手机号');
        return false;
      }
      try {

        await scqBaofooBindBankSms({
            phone:bankMobile,
            smsType:'ADD_BANK_CARD_CODE'
        }).then((res)=>{

          console.log('ADD_BANK_CARD_CODE',res)

          this.bankTimeGo();
        })


    } catch (err) {
          console.log(err)
          Toast.fail(err.message || err.msg);
        }
    },

     //添加银行卡
     async  addBankAction() {
      let { bankCardNo, bankMobile, platformMsgCode } = this.addBankData;
      if (!REGEX_BANK_CODE.test(bankCardNo)) {
        Toast.fail('请输入正确的银行卡号');
        return false;
      }
      if (!REGEX_MOBILE.test(bankMobile)) {
        Toast.fail('请输入正确的手机号');
        return false;
      }

      if (platformMsgCode.length !== 4) {
        Toast.fail('请输入正确的验证码');
        return;
      }
      if (!this.agreexybool) {
        Toast.fail('请先阅读协议');
        return
      }
      let params={
        ownerBankNumber: this.addBankData.bankCardNo,
        id: this.OrderClientVo.authenticateVo.orderId,
        code: this.addBankData.platformMsgCode,
        ownerBank:this.addBankData.ownerBank,
      }
        await scqUserBankSave(params).then((res)=>{
          console.log(res)
          this.orderBankRepay();
          this.show = false
          this.hideCardChoseViewAction(); // 清空
        })



        .catch(err => {
          Toast.fail(err.message || err.msg);
        });
    },





    async getBankLimit() {
        try {
          let res = await showBankLimit()
          let bankLimitList= []
          let bankCodeList = []
          for( let i in res.orderConstBank ){
            bankLimitList.push(res.orderConstBank[i])
            bankCodeList.push(i)
          }
          this.bankLimitList = bankLimitList || [];
          this.bankCodeList = bankCodeList

        } catch (error) {
          Toast.fail(error.msg);
        }
      },

      bindPickerChange (data, index) {
      console.log("picker发送选择改变，携带值为", data, index)
      this.bankIndex = index;
      this.info = data;
      this.show = false
    },


  }
}

</script>
<style lang="scss" scoped>
page {
  background: #f5f7f9;
}

.van-cell {
  width: 102%;
  font-size: 13px;
}
.m_l_16 {
    margin-left: 12.267vw;
}

.step-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: -260px;
  padding: 0 80px 30px 80px;
  .step {
    font-size: 28px;
    opacity: 0.48;
    min-width: 120px;
    .round {
      width: 48px;
      height: 48px;
      line-height: 48px;
      background: #FFFFFF;
      border-radius: 50%;
      color: #2396F5;
      text-align: center;
      margin: auto;
    }
    .txt {
      margin-top: 16px;
      color: #fff;
      text-align: center;
    }
  }
  .hr {
    opacity: 0.4;
    width: 330px;
    height: 50px;
    border-top: 4px dashed #fff;
  }
  .active {
    opacity: 1;
    .round {
      width: 60px;
      height: 60px;
      line-height: 64px;
      border: 8px solid #a6cbff;
    }
  }
}

.material-content {
   .search-icon {

    font-size: 16px;
    line-height: inherit;


  }

  .van-icon {

    font-size: 28PX;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
}
  .bottom-btn {
    background: #ffffff;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 10;
    width: 100%;

    box-sizing: border-box;
}
  .banner {
    height: 200px;
    background: url("../../static/images/bank-bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    padding: 16px 24px;
    box-sizing: border-box;

    .bottom {
      color: #fff;
      text-align: left;
      margin-top: 30px;

      .tips {
        font-size: 40px;
        margin-bottom: 10px;
      }

      .info {
        color: rgba(255, 255, 255, 0.7);
      }
    }
  }

  .clause {
    font-size: 24px;
    margin-bottom: 16px;

    .clause-info {
      color: #999;
      align-items: center;
      font-size: small;

      .info {
        color: #2b8eff;
      }
    }
  }



    .remarks {
      flex: 0 0 80%;
      font-size: 28px;
      text-align: right;
      align-items: center;
      justify-content: flex-end;


      button {
        background: #2b8eff;
        height: 26px;
        line-height: 26px;
        font-size: 14px;
        margin-left: 20px;
        margin-right: 0;
        width:100px;

        &.disabled {
          background: #8fc3ff;
        }
      }

      .code {
        font-size: 18px;
        color: #3b4144;
      }

      &.phone {
        flex: 0 0 50%;
      }
    }


  .container {
    padding: 0 24px;
    padding-bottom: 160px;
  }
  .mp260 {
    margin-top: -260px;
  }

  .popup-down-wrapper {
    max-height: 80vh;
    overflow-y: scroll;
    padding: 0px 12px 0px 12px;

    .popup-down {
      display:flex;
      flex-direction: column;
      justify-content: center;
      border-radius: 30px 30px 0px 0px;
      align-items: stretch;
      background-color: #FFFFFF;
      padding: 0px 23px;

      .popup-down-header {
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid #eee;

        .header-icon {
          margin-top: 12px;
          width: 71px;
          height: 6px;
          background: #e5e5e5;
          border-radius: 3px;
        }

        .header-title {
          display:flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          margin: 43px 0px;
          font-size: 18px;
          font-weight: 800;
          color: #313131;

          .left-image {
            float: left;
            width: 24px;
            height: 40px;
            transform:rotate(180deg);
          }

          .title {
            text-align: center;
            flex: 1;
          }
        }
      }

      .popup-down-content {
        max-height: 372px;

        .card-item {
          display:flex;
          align-items: center;
          justify-content: space-between;
          padding: 45px 44px;
          font-size: 26px;
          color: #313131;
          border-bottom: 1px solid #eee;

          .l {
            text {
              margin-left: 11px;
            }
          }
        }
      }

      &.popup-down-select {
        .popup-down-footer {
          font-size: 16px;
          font-weight: bold;
          padding: 45px 44px;
          text-align: center;
          color: #3984ff;
        }
      }

      &.popup-down-add {
        .popup-down-footer {
          .btn-add {
            margin: 31px 0px 59px 0px;
            font-size: 30px;
            color: #FFFFFF;
            background-color: #3984FF;
          }
        }

        .popup-down-add-content{
          .row{
            display:flex;
            justify-content: center;
            align-items: center;
            padding: 55px;
            border-bottom: 1px solid #eee;
            .label{
              font-size:26px;
              color:#313131;
            }
            .r{
              flex: 1;
              text-align: right;
              margin-left: 20px;

              .input-placeholder{
                font-size:26px;
                color:#959595;
              }
            }

            &.code{
              .r{
                display:flex;
                justify-content: center;
                align-items: center;
                input{
                  flex: 1;
                  margin-right: 10px;
                }
                button{
                  padding: 10px 23px;
                  font-size:26px;
                  color:#3984FF;
                  background:#FFFFFF;
                  border:1px solid #9FC3FF;
                  border-radius:4px;
                  line-height: unset;

                  &.disabled{
                    pointer-events: none;
                    color: #959595;
                  }
                }
              }
            }
          }
        }
      }

    }
  }

  .agree-view {
    margin: 30px 0px 0px 0px;
    display: flex;
    align-items: center;
    .agreexyView {
      padding: 14px;
    }
    .agree-image {
      width: 25px;
      height: 25px;
    }

    .xx-textview {
      font-size: 24px;
    }
  }

  .tips-iphone {
    position: absolute;
    left: 162px;
    top: 30px;
    width: 36px;
    height: 36px;
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAADK0lEQVRYR8VXTUhUURT+zlUxFJKCKIIWtaigiKAgG2hmzlOzKGgRSRGu+qEgqBZtapEuyk0QtaiNgrQwaBlGlLx3X1oaEW2CoAgSEkLcVBDB4Hjiyszwxt59746z8MLAzJxzvvOd75577nuEFV60wvlREwHf9w8qpbqIKCMi6wGYj1mzRDQrIu8AvAIQMvNPl+KcCPi+f4qILhPRPhdQADMA7s3Nzd3v6ekpJMUkEhgbG2trbGwcAnDcMXGVGxF9UEr1ZrPZT7Z4K4GJiYktxWJxQkQ2Lid5NKahoWGHjUQsAa31BgA/6k0cjVdK7crlch+XYtoIjAM44EBghoi+i8hqEdlERKsTYqbn5+d3d3V1/Yr6/EcgDMOLIvIgAeiZiIx4njey1CcIgvNEdBXAdkv8IDOfsxIYHR1d09raOgVgmwXgdXNz88FMJvPXRtD3/YxSygewKs5HRDo9zzP2xVWlgO/7fUqpmwnV72fmt2X75OTk2kKhcFpEnjPz1/L/WutBAGficIjoaT6fPxZLQGv9HsAeC4EpZs6Ubb7v9yqlHpV+DzHz2bItDMMTIvLEokChpaVlXXt7++8qBYIg2EdEleqWBi8sLPQ3NTWNZbPZN8amtTaTrq3kN83MmyMKXAFw16akiBzyPO9FFYEwDG+JyHWHzoeIfCGirWVfQ66jo6MvosAjEem1YRHR7Xw+f6OKQNK+pZC6w8zXXOSP4FS2rNKEWutRAEdcFLBVrrU2jWcaMG09Y+ajSxWoiYCIvPQ8rzuy7w8BXEjLXLLHErAeHUs3D3iet9gzWuuTAB47Jjdu/29BLU1YSsTMHJrvQRCMENEpVwKxTZh2DGPAF5NHVt6VQOwxLEmZNIiq8M3Ri/6RMkErriISP4iMh8MorgAxc/QEmeq1iwKJo9jhMqqbQOJlZNAdruNFEstUIPk6jpxp1wcSF9XLPm4PJKVmXNlHMkNifHx8XbFYDADsrKXMGN/PSqnDuVzuWxxO6nuB1tqMVzNml7OGC4XCpe7u7j+24FQCpS3ZC2AAQKcji2kA/cw8nObvRKAMsmKvZmlV1GOvSYF6Etli/wF22mQwqBwp7gAAAABJRU5ErkJggg==") no-repeat;
    background-size: 100%;
  }

  .gzh-img {
    display: block;
    width: 654px;
    height: 874px;
  }

  .pdf-box {
    position: fixed;
    top: 80px;
    bottom: 0;
    width: 100%;
    background: #fff;
    display: flex;
    flex-direction: column;
    z-index: 11;
    .btn {
      margin: 10px 15px;
    }
  }

}
.zifang-min{
    display: flex;
    align-items: center;
    margin-top: 10px;
    uni-button{
        font-size: 12px;
        width: 80px;
        line-height: 1.4em;
        padding: 5px 0;
        display: block;
        border: 1px solid #007aff;
    }
}
.zifang-pop{
    background-color: #fff;
    padding: 10px;
    border-radius: 5px;
    margin-top: 10px;
    span{
        font-size: 14px;
    }
    uni-input{
        border: 1px solid #e8e8e8;
        padding: 5px 10px;
        border-radius: 4px;
        font-size: 12px;
    }
    .uni-input-placeholder{
        font-size: 12px;
    }
}
.button-zi{
    display: block;
    margin: 5px;
    text-align: center;
    font-size: 14px;
    color: #2b8eff;
    margin-top: 10px;
}
</style>

<template>
  <div class="min_h_100_vh dis_flex flex_dir_col bg_col_F5F7F9">
    <!-- 全民经纪人 -->
    <div>
      <div class="dis_flex ali_it_cen m_0_16 m_t_24">
        <img
          class="w_24 h_24 dis_flex"
          src="/favicon.png"
        />

<!--        <img-->
<!--          class="w_88 dis_flex m_l_4"-->
<!--          src="http://songcw-dev.oss-cn-shanghai.aliyuncs.com/car-agent/20220616/tsnfDjUbOqTXKmtqLRQt.png"-->
<!--        />-->

        <!--  -->
      </div>

      <!-- 大图 -->
      <div class="dis_flex ju_con_cen m_t_24">
        <img
          class="w_72_vw dis_flex"
          src="http://songcw-dev.oss-cn-shanghai.aliyuncs.com/car-agent/20220616/JrlBkIQpjSzlREuLgEJv.png"
        />
      </div>

      <!--  -->
    </div>

    <!-- 块 -->
    <div class="bg_col_fff box_sha_2_1_10_0_black_10_per bor_rad_24 m_0_16 m_t_24">
      <!-- 项 -->
      <div
        class="
          dis_flex
          ali_it_cen
          bg_col_rgba_35_150_245_4_per
          bor_rad_24
          box_sha_0_2_4_0_black_80_per
          p_16
          m_0_16 m_t_24
        "
      >
        <!-- 图标 -->
        <img
          class="w_24 h_24 dis_flex"
          src="http://songcw-dev.oss-cn-shanghai.aliyuncs.com/car-agent/20220616/IjhFMboCRJuAAZcXUTuh.png"
        />

        <input
          class="w_0 flex_1 font_16 bor_none bg_col_tra m_l_8"
          v-model="form.mobile"
          type="text"
          placeholder="请输入您的手机号码"
          maxlength="11"
          @input="
            (e) => {
              let v = e.currentTarget.value;
              v = (v || '').replace(/[^0-9]/g, '');
              form = { ...form, mobile: v };
            }
          "
        />

        <!--  -->
      </div>

      <!-- 项 -->
      <div
        class="
          dis_flex
          ali_it_cen
          bg_col_rgba_35_150_245_4_per
          bor_rad_24
          box_sha_0_2_4_0_black_80_per
          p_16
          m_0_16 m_t_24
        "
      >
        <!-- 图标 -->
        <img
          class="w_24 h_24 dis_flex"
          src="http://songcw-dev.oss-cn-shanghai.aliyuncs.com/car-agent/20220616/EaMKppxGbMxNyuDBGCVJ.png"
        />

        <input
          class="w_0 flex_1 font_16 bor_none bg_col_tra m_l_8"
          v-model="form.sms_code"
          type="text"
          placeholder="请输入您的验证码"
          maxlength="4"
          @input="
            (e) => {
              let v = e.currentTarget.value;
              v = (v || '').replace(/[^0-9]/g, '');
              form = { ...form, sms_code: v };
            }
          "
        />

        <div class="font_14 col_0A82E6" @click="sendSMS()">
          {{ smsCountDownSecond ? `${smsCountDownSecond}秒后重试` : '发送验证码' }}
        </div>

        <!--  -->
      </div>

      <!-- 登录 -->
      <div class="dis_flex m_0_16 m_t_24">
        <van-button class="flex_1 font_16 bor_rad_24" type="info" @click="submit()"
        >登录</van-button
        >
      </div>

      <!-- 注册 -->
      <!-- DEPRECATED 2022-10-24 -->
      <!-- <div class="dis_flex ju_con_cen m_t_24">
        <div
          class="font_14 col_0A82E6 active_opa_75_per"
          @click="
            $router.push(
              `/register?fromIndex=1&officialAccountKey=${
                officialAccountKey || ''
              }`
            )
          "
        >
          新用户注册
        </div>
      </div> -->

      <!-- 留白 -->
      <div class="h_24"></div>

      <!--  -->
    </div>

    <!-- 留白 -->
    <div class="h_40"></div>

    <!--  -->
  </div>
</template>

<script>
  import { Toast } from 'vant';
  const moment = window.moment;
  import {  isMobile } from '@/utils/tools';

  import {
    oauthTokenPost,
    commonNationalBrokerSmsPost,
  } from '@/api/index';

  export default {
    components: {},
    data() {
      return {
        code: null,
        officialAccountKey: '',
        form: {},

        /**
         * 短信倒计时
         */
        smsCountDownSecond: 0,
        smsTimeout: null,

        //
      };
    },
    computed: {},
    mounted() {
      const u = JSON.parse(localStorage.userInfo || '{}');

      // 有用户信息 且没过期 就直接前往
      if (u.token && moment().isBefore(u.expiresTime)) {
        this.$router.replace('/index');
      }

      //
    },
    destroyed() {
      clearTimeout(this.smsTimeout);
    },
    methods: {
      // 提交
      async submit() {
        try {
          if (!isMobile(this.form.mobile)) {
            throw '请输入正确的手机号';
          }

          if (!this.form.sms_code) {
            throw '请输入验证码';
          }

          Toast.loading({
            duration: 10 * 1000,
            message: '请稍后..',
            forbidClick: true,
          });

         // const u = JSON.parse(localStorage.userInfo);

          let data = await oauthTokenPost({
            phone: this.form.mobile,
            msgCode: this.form.sms_code,
          });

          localStorage.userInfo = JSON.stringify({
            ...data,
            expiresTime: moment()
              .add(data.expires_in - 600, 'seconds') // 令牌有效期提前10分钟
              .format('YYYY-MM-DD HH:mm:ss'),
          });

          Toast('登录成功');

          this.$router.replace('/index');
          //
        } catch (err) {
          Toast(err);
        }

        //
      },


      // 倒计时
      smsCountDown(seconds) {
        this.smsCountDownSecond = --seconds;

        if (seconds > 0) {
          this.smsTimeout = setTimeout(() => {
            this.smsCountDown(seconds);
          }, 1000);
        }

        //
      },

      // 发送短信
      async sendSMS() {
        if (this.smsCountDownSecond > 0) return;

        try {
          if (!isMobile(this.form.mobile)) {
            throw '请输入正确的手机号';
          }

          Toast.loading({
            duration: 10 * 1000,
            message: '请稍后..',
            forbidClick: true,
          });

          await commonNationalBrokerSmsPost({
            phone: this.form.mobile,
          });

          Toast.clear();

          this.smsCountDown(60);

          //
        } catch (err) {
          Toast(err);
        }

        //
      },

      //
    },
  };
</script>

<template>
  <div class="min_h_100_vh dis_flex flex_dir_col bg_col_F5F7F9">

    <van-nav-bar title="我要还款"  right-text="退出" @click-right="logout()" >
      <template #left>
        欢迎您，<span class="font_bold">{{ userInfo.userName }}</span>
      </template>
    </van-nav-bar>


    <van-notice-bar
      v-if="financing && financing.financingOverdue"
      text="警示:您处于违约中,请在工作时间联系人工客服400-008-3336。"
    />

    <!-- 还款 -->
    <div
      class="
        bg_col_fff
        bor_rad_4
        box_sha_0_0_8_8_black_80_per
        m_0_16 m_t_16
      "
    >
      <div class="p_16">

      <!-- <div class="font_16">我要还款</div> -->
    <van-nav-bar  right-text="换卡绑定" @click-right="bindBankCar()" >
      <template #left>
      <span class="font_bold">{{ '我要还款' }}</span>
      </template>
    </van-nav-bar>

    <van-nav-bar  right-text="合同查看" @click-right="showContract()" >
      <template #left>
      <span class="font_bold">{{ '合同' }}</span>
      </template>
    </van-nav-bar>


      <!-- 剩余总待还 -->
      <div
        class="
          dis_flex
          ali_it_cen
          bg_col_lin_270_E2E5F9_DCF2FF
          bor_rad_4
          m_t_16
        "
      >
        <div class="flex_1 m_l_16">

          <van-row gutter="20">
            <van-col span="12">
              <div class="font_12 col_999">申请金额（元）</div>
              <div class="font_16 m_t_8">{{order?order.putOutAmt:'0.00'}}</div>
            </van-col>
            <van-col span="12">
              <div class="font_12 col_999">授信额度（元）</div>
              <div class="font_16 m_t_8">{{order?order.approveAmt:'0.00'}}</div>
            </van-col>
          </van-row>
        </div>

        <img
          class="h_88 dis_flex"
          src="http://songcw-dev.oss-cn-shanghai.aliyuncs.com/car-agent/20220714/VemUCGNDvhedSCeuyJUl.png"
        />

      </div>
      </div>
      <!-- 借款详情 -->
      <van-collapse v-model="activeNames" >
        <van-collapse-item title="借款详情" name="1">
            <van-cell title="产品名称" :value="order?order.productName:'-'" />
            <van-cell title="申请日期" :value="order?order.createTime:'-'" />
            <van-cell title="订单编号" :value="order?order.orderNumber:'-'" />
            <van-cell title="借款期数" :value="financing?financing.financingPeriods:'-'+'期'" />
        </van-collapse-item>
      </van-collapse>
    </div>

    <!-- 还款 -->
    <div
      class="
        bg_col_fff
        bor_rad_4
        box_sha_0_0_8_8_black_80_per

        m_0_16 m_t_16
      "
    >

      <!--已还清-->

      <van-collapse v-model="activeName"  >
        <van-collapse-item :title="'已还清'+(payedList?payedList.length:0)+'期'"  :name="payList?2:1" >

          <template #value>
            <span  class="h_40 m_r_32" v-if="payList"   @click="prepayCalc">提前结清</span>
          </template>


          <div v-for="item in payedList" :key="item.termNum" >
            <van-cell-group>
              <van-cell class="p_l_0 p_r_0"  :border="false" center label-class="font_16 col_333" value-class="col_66BB6A"  title-class="font_12 col_999" :title="'第'+item.platformPeriod+'期 还款日'+item.platformDate" >

                <template  #label>
                  <span>{{item.repaymentTotalAmt || item.capitalDamagesRepay+item.capitalPrincipalRepay+item.capitalInterestRepay+item.capitalDinterestRepay+item.capitalExpensesRepay}}元</span>
                </template>

                <template>
                  <van-tag type="success" size="medium">支付完成</van-tag>
                </template>
              </van-cell>
              <van-cell class="p_l_0 p_r_0" :border="false"  title="还款编号" :value="item.repayOrderNo || '-'" />
              <van-cell class="p_l_0 p_r_0" :border="false"  title="还款时间" :value="item.platformPayDate || '-'" />
            </van-cell-group>
         </div>
        </van-collapse-item>
      </van-collapse>

      <!--待还款-->
      <div v-for="item in payList" :key="item.termNum" >
      <van-cell   center label-class="font_16 col_333"  :title="'第'+item.platformPeriod+'期 还款日'+item.platformDate" title-class="font_12 col_999"    >


        <template #label>
          <span>{{item.remainTotalAmount}}元</span>
          <van-tag type="danger" class="m_l_16" size="medium" v-if="item.platformStatus==3">已逾期</van-tag>
        </template>

        <template v-if="item.platformDate <= order.nowTime">
          <van-button round color="linear-gradient(to right, #91bbe5, #469df5)"  :disabled="repayDisabled"  @click="repayH5(item.id)" size="mini" class="p_12_16">还款</van-button>
        </template>

        <template  v-else-if="item.platformStatus==1">
          待还款
        </template>

      </van-cell>
      </div>
      <div v-if="!order || (!order.payedList && !order.payedList)">
        <van-empty description="暂无数据" />
      </div>
    </div>
    <!-- 留白 -->
    <div class="h_32"></div>
    <!--  -->

<!--    <van-tabbar class="h_40" v-if="payList" >-->
<!--      <van-button  class="h_40" type="primary" block @click="prepayCalc">提前结清</van-button>-->
<!--    </van-tabbar>-->
  </div>
</template>

<script>
import { Toast } from 'vant';

import {authRevokeDelete, getCalculate, repayH5} from '@/api/index';

export default {
  components: {},
  data() {
    return {
      userInfo: {},
      authUserInfo: {},
      activeNames: ["1"],
      activeName:["1"],
      payList:[],
      payedList:[],
      financing:{},
      order:{},
      repayDisabled: false

      //
    };
  },
  computed: {},
  mounted() {
    if (localStorage.userInfo) {
      const u = JSON.parse(localStorage.userInfo);
      console.log(";;;;;;;;",u)
      this.userInfo = u;
    }

    this.calculate();


    //
  },
  methods: {
    /**
     * 获取还款计划
     * @returns {Promise<void>}
     */
    async calculate() {

      try {
        let orderFinancing = await getCalculate();

        this.order = orderFinancing;
        this.financing = orderFinancing.orderFinancing;
        this.payList = orderFinancing.orderFinancingPlatformVoList;
        this.payedList = orderFinancing.payedList;
      }catch (err){
        Toast(err)
      }
    },

    bindBankCar(){
          this.$router.replace('/bankCard');
    },

    prepayCalc(){

      this.$router.replace('/prepayCalc');
    },

    showContract(){
      this.$router.replace('/contract');
    },

    /**
     * 还款
     * @returns {Promise<void>}
     */
    async repayH5(id) {

      try {

              Toast.loading({
                duration: 10 * 1000,
                message: '请稍后..',
                forbidClick: true,
              });
            this.repayDisabled = true;
            let result = await repayH5({"id":id});

            if(result.status == 200){
              window.top.location.href = result.data.h5Url;
              this.repayDisabled = false;
            }else {
              Toast.fail(result.msg);
              this.repayDisabled = false;
            }
      }catch (err){
        Toast(err)
      }

    },

    // 登出
    logout() {
      this.$dialog
        .confirm({
          title: '提示',
          message: '您是否确认登出？',
        })
        .then(async () => {
          try {
            Toast.loading({
              duration: 10 * 1000,
              message: '请稍后..',
              forbidClick: true,
            });

            await authRevokeDelete(this.userInfo.token);

            Toast.clear();

            localStorage.removeItem('userInfo');
            this.$router.replace('/login');

            //
          } catch (err) {
            Toast(err);
          }

          //
        })
        .catch(() => {});

      //
    },


    //
  },
};
</script>

<template>
  <div class="link-home">
    <div class="header">
      <van-nav-bar
        :title="$store.state.iframeTitle"
        left-arrow
        @click-left="onClickLeft"
      ></van-nav-bar>
      <van-icon name="cross" class="close" @click="closeTab" />
    </div>

    <div id="iframe-container">
      <iframe :src="$store.state.iframeSrc" scrolling="auto" frameborder="0" id="iframe"></iframe>
    </div>
  </div>
</template>

<script>


export default {
  mounted() {
    let iframe = document.getElementById('iframe')


    iframe.onload = function() {
    
    }
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1)
     
    },

    closeTab() {
      
      this.$router.replace('/bankCard')
      
    }
  }
}
</script>
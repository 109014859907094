import * as http from '@/utils/http';


// 根据手机号码发送验证码
export const commonNationalBrokerSmsPost = async (data) => {
  const res = await http.post(`/repay/sms`,data);
  return res.data;
};

// 登出
export const authRevokeDelete = async (token) => {
  const res = await http.get(`/repay/logout`
    + `?token=${token}`);
  return res?.data;
};


// 登录
export const oauthTokenPost = async (data) => {

  const res = await http.post(`/repay/login`, data);
  return res?.data || {};
};

// 还款计划
export const getCalculate = async () => {
  const res = await http.post(`/repay/calculate`);
  return res?.data || {};
};


// 还款计划
export const getPrepayCalc = async () => {
  const res = await http.post(`/repay/prepayCalc`);
  return res?.data || {};
};


//用户信息
export const getOrderDetail = async () => {
  const res = await http.get('/repayBank/query/apply');
  return res?.data || {};
};

export const  showBankLimit = async () =>  {
  const res = await http.get(`/repayBank/enum/all`);
  return res?.data || {};
};

export const  orderBankRepay = async () =>  {
  const res = await http.get(`/repayBank/bankCard/list`);
  return res?.data || {};
};


export const  userBankList = async () =>  {
  const res = await http.get('/repayBank/bankCard/list');
  return res?.data || {};
};



export const  bankCardBin = async (data) =>  {
  const res = await http.post(`/repayBank/bankCard/smsCode`,data);
  return res?.data || {};
};


/**
     * 平台协议绑卡发送短信
     * 发送添加银行卡验证码
     * @param {Object} mobile
     *
     */
export const  scqBaofooBindBankSms = async (data) =>  {
  const res = await http.post(`/repayBank/sms?phone=${data.phone}&smsType=${data.smsType}`);
  return res?.data || {};
};


export const  scqUserBankSave = async (data) =>  {
  const res = await http.post("/repayBank/bankCard/add", data);
  return res?.data || {};
};



// 还款
export const repayH5 = async (data) => {
  const res = await http.post(`/repay/repayH5`,data);
  return res || {};
};

// 提前还款
export const prepayCalcPayH5 = async (data) => {
  const res = await http.post(`/repay/prepayCalcPayH5`,data);
  return res || {};
};


//银行卡绑定 - 换卡
export const reBankBind = async  (data) =>{
  const res = await http.post('/repayBank/bankCard/rebin', data);
  return res || {};
}


export const showContract = async  () =>{
  const res = await http.get('/contract/get/file');
  return res || {};
}

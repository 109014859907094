<template>
  <div class="min_h_100_vh dis_flex flex_dir_col bg_col_F5F7F9">
    <!-- 标题 -->
    <div class="pos_rel">
      <!-- 背景 -->
      <img
        class="w_100_per dis_flex"
        src="http://songcw-dev.oss-cn-shanghai.aliyuncs.com/car-agent/20220714/kVNrliAQbLZLaGJIWPgB.png"
      />

      <!-- 内容 -->
      <div class="pos_abs l_0 t_0 w_100_per dis_flex flex_dir_col">
        <div class="dis_flex ali_it_cen m_0_16 m_t_24">
          <img
            class="w_24 h_24"
            src="http://songcw-dev.oss-cn-shanghai.aliyuncs.com/car-agent/20220714/FJxnZYxlluGNiCTjsZIx.png"
          />

          <div class="font_20 m_l_16">进件数据</div>

          <!--  -->
        </div>

        <!--  -->
      </div>

      <!--  -->
    </div>

    <!-- 收缩 -->
    <div class="m_t_neg_144"></div>

    <!-- 当日统计 -->
    <CurrentDayStatistic class="pos_rel" />

    <!-- 进件金额|额度批复|成交金额 -->
    <PrepareStatistic />

    <!-- 订单状态统计图 -->
    <OrderStatusTrend />

    <!-- 放款走势图 -->
    <GrantTrend />

    <!-- 进件转化 -->
    <PrepareTranslate />

    <!-- 省长列表 -->
    <GovernorList />

    <!-- 留白 -->
    <div class="h_32"></div>

    <!--  -->
  </div>
</template>

<script>
export default {
  components: {
    CurrentDayStatistic: () => import('./components/CurrentDayStatistic.vue'),
    PrepareStatistic: () => import('./components/PrepareStatistic.vue'),
    OrderStatusTrend: () => import('./components/OrderStatusTrend.vue'),
    GrantTrend: () => import('./components/GrantTrend.vue'),
    PrepareTranslate: () => import('./components/PrepareTranslate.vue'),
    GovernorList: () => import('./components/GovernorList.vue'),
  },
  data() {
    return {
      loanGeneralStatistics: {},

      //
    };
  },
  computed: {},
  mounted() {
    //
  },
  methods: {
    //
  },
};
</script>
